import { CollectionService, LinkService } from '@services'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import { LINK_TYPE, COLLECTION_TYPE } from '@constants'
export default {
  data() {
    return {
      collectionList: [],
      params: {
        page: 1,
        limit: 16
      },
      currentPage: 1,
      totalPages: 0,
      totalCollection: 0,
      sharedData: null,
      actionKey: 0,
      actions: [
        {
          name: 'Share',
          value: 'share',
          icon: 'mdi-share',
          show: true
        },
        {
          name: 'Set cover image',
          value: 'setCoverImage',
          icon: 'mdi-image-size-select-actual',
          show: true
        },
        {
          name: 'Remove',
          value: 'delete',
          icon: 'mdi-delete',
          show: true
        },
        {
          name: 'Remove',
          value: 'delete-link',
          icon: 'mdi-delete',
          show: true
        },
        {
          name: 'Rename',
          value: 'rename',
          icon: 'mdi-form-textbox',
          show: true
        }
      ],
      breadCrumbPages: [],
      parent: null,
      currentCollection: null,
      intersectionObserver: null,
      rank: {
        min: null,
        max: null,
        affectedRanks: []
      },
      locationList: []
    }
  },

  beforeRouteLeave(to, from, next) {
    this.destroyIntersectionObserver()
    next()
  },

  beforeDestroy() {
    if (this.intersectionObserver) {
      this.destroyIntersectionObserver()
    }
  },

  methods: {
    async getCollections(collectionSlug = null) {
      this.$loading.spinner = true
      if (collectionSlug) {
        this.params.parentId = collectionSlug
      }
      this.params.sortBy = 'rank'
      this.params.type = this.gridType
      const response = await CollectionService.getAll(this.params)
      if (response) {
        // Tempararily added for work
        if (this.params?.onlyCover) {
          this.collectionList = concat(this.collectionList, response.data?.data)
        } else {
          this.collectionList =
            this.params.page > 1
              ? concat(this.collectionList, response.data?.data)
              : response.data?.data
        }
        this.totalCollection = response.data.count || 0
        if (response.data?.totalPages) this.totalPages = response.data?.totalPages
        if (response.data?.parent) this.parent = response.data?.parent

        if (this.parent) this.$parent.parent = this.parent

        this.currentPage = this.params?.page
        //if (this.$route?.name == 'collection.share.edit') {
        if (!this.totalPages) this.totalPages = 1

        if (
          this.currentPage == this.totalPages &&
          this.$route.name == 'collection.view' &&
          !this.params?.onlyCover &&
          this.gridType == LINK_TYPE.COLLECTION
        ) {
          this.triggentToLoadLocations()
        }
      }
      this.$loading.spinner = false
    },

    async getCollectionData(linkId, params = {}, gridType = LINK_TYPE.COLLECTION) {
      this.$loading.spinner = true
      let currentLinkId = this.currentCollection?.linkId ? this.currentCollection?.linkId : linkId
      this.params.sortBy = 'rank'
      this.params.type = gridType
      this.params = { ...this.params, ...params }
      const response = await LinkService.getAll(currentLinkId, this.params)
      if (response) {
        this.collectionList =
          this.params.page > 1
            ? concat(this.collectionList, response.data?.data)
            : response.data?.data
        this.totalCollection = response.data.count
        if (response.data?.totalPages) this.totalPages = response.data?.totalPages
        this.currentPage = this.params?.page
        //if (this.$route?.name == 'collection.share.edit') {
        if (!this.totalPages) this.totalPages = 1

        if (this.currentPage == this.totalPages) this.triggentToLoadLocations()
        //}
        // if (response.data?.parent) this.currentOpenedCollecton = response.data?.parent
      }
      this.$loading.spinner = false
    },

    async getLinkData(linkId, updateData = true) {
      const response = await LinkService.get(linkId)

      if (response) {
        if (this.$route?.params?.shareId == response.data?.linkId) {
          this.sharedData = response.data
          if (!updateData) {
            this.setCurrentOpen(this.currentCollection)
          } else {
            this.setCurrentOpen(this.sharedData)
          }
        } else {
          this.currentCollection = response.data
          this.setCurrentOpen(this.currentCollection)
        }
        this.actionKey++
      }
    },

    async getCollectionPath(collection) {
      const isCollectionSetupPage = this.$route.name == 'collection.share.edit'
      const response = await LinkService.getPath(collection?.linkId)
      if (response) {
        this.breadCrumbPages = response?.data?.map((obj, index) => {
          if (isCollectionSetupPage) {
            let privateName = obj.meta?.collectionId?.name || obj.meta?.locationId?.name
            let titleText = privateName || obj?.title
            obj = { ...obj, title: titleText }
            if (obj.level === 0) {
              titleText = `${privateName} share setup`
              obj = { ...obj, title: titleText }
            }
          }
          return obj
        })
        const copyOfData = cloneDeep(collection)
        copyOfData.disabled = true
        if (this.breadCrumbPages?.length > 0 && isCollectionSetupPage) {
          copyOfData.title =
            copyOfData.meta?.collectionId?.name || copyOfData.meta?.locationId?.name
        }
        this.breadCrumbPages.push(copyOfData)
      }
    },

    registerObserver() {
      this.intersectionObserver = new IntersectionObserver(this.handleIntersect, {
        root: null,
        rootMargin: '0px 0px -10px 0px',
        threshold: 1
      })

      this.intersectionObserver.observe(this.$refs?.endOfListIndicator)
    },

    destroyIntersectionObserver() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect()
        this.intersectionObserver = null
      }
    },

    async handleIntersect(entries) {
      const [entry] = entries

      if (entry.isIntersecting) {
        this.params.page++
        if (this.params?.onlyCover) {
          if (
            this.totalCollection == this.collectionList?.length &&
            this.gridType == COLLECTION_TYPE.COLLECTION
          ) {
            this.gridType = COLLECTION_TYPE.LOCATION
            this.params.page = 1
            this.totalPages = 1
            this.totalCollection = 0
          }

          if (
            !this.totalCollection ||
            (this.params?.page > 1 && this.params?.page <= this.totalPages)
          ) {
            await this.getCollections(this.currentCollection)
          }
          if (
            this.gridType == COLLECTION_TYPE.LOCATION &&
            this.params?.page == 1 &&
            this.collectionList?.length == 0
          ) {
            this.recordsNotFoundFlag = true
          }
        } else if (this.totalCollection > this.collectionList?.length) {
          if (['collection.view', 'collection.list'].includes(this.$route.name)) {
            await this.getCollections(this.currentCollection)
          } else {
            await this.getCollectionData(this.currentCollection?.linkId, {}, this.gridType)
          }
        }
      }
    },

    getName(item) {
      let displayName = item?.title || item?.name
      if (this.$route?.name === 'collection.share.edit') {
        if (item?.meta?.locationId) {
          displayName = item?.meta?.locationId?.name
        }
        if (item?.meta?.collectionId) {
          displayName = item?.meta?.collectionId?.name
        }
      }
      return displayName
    },

    findMinMaxNumbersWithIndexes(affectedRanks) {
      return affectedRanks.reduce(
        (result, obj) => {
          ;['oldIndex', 'newIndex'].forEach((key) => {
            if (obj[key] < result.min.value) {
              result.min = { value: obj[key], index: key }
            }
            if (obj[key] > result.max.value) {
              result.max = { value: obj[key], index: key }
            }
          })
          return result
        },
        {
          min: { value: Number.MAX_VALUE, index: '' },
          max: { value: Number.MIN_VALUE, index: '' }
        }
      )
    },

    retrieveAffectedRanks(event) {
      if (event.newIndicies?.length) {
        event.newIndicies.forEach((_item) => {
          const sortableIndex = _item.multiDragElement?.sortableIndex
          const newIndex = _item.index
          const oldIndexMedia = this.collectionList[sortableIndex]
          const newIndexMedia = this.collectionList[newIndex]
          if (oldIndexMedia && newIndexMedia && oldIndexMedia?.rank != newIndexMedia?.rank) {
            this.rank.affectedRanks.push({
              oldIndex: oldIndexMedia.rank,
              newIndex: newIndexMedia.rank
            })
          }
        })
      } else {
        const oldIndexMedia = this.collectionList[event.oldIndex]
        const newIndexMedia = this.collectionList[event.newIndex]
        if (oldIndexMedia && newIndexMedia && oldIndexMedia?.rank != newIndexMedia?.rank) {
          this.rank.affectedRanks.push({
            oldIndex: oldIndexMedia.rank,
            newIndex: newIndexMedia.rank
          })
        }
      }
      if (this.rank?.affectedRanks?.length) {
        const { min, max } = this.findMinMaxNumbersWithIndexes(this.rank.affectedRanks)
        this.rank.min = min
        this.rank.max = max
        if (['collection.view', 'collection.list'].includes(this.$route?.name)) {
          this.collectionChangeRank()
        } else {
          this.changeRank()
        }
      }
    },

    resetRank() {
      this.rank = {
        min: null,
        max: null,
        affectedRanks: []
      }
    }
  }
}
